


import { envsEnum } from '@ui/helpers/enums';
import { brandName } from '@ui/helpers/constants';
import Head from 'next/head';

const NextHeadTag = ({
    title = brandName,
    canonicalUrl,
    metaTitle,
    metaDesc,
    metaKeywords,
    metaIndex = 'index, follow',
}) => {
    // Determine the value of metaRobotsContent based on the environment
    const metaRobotsContent = process.env.NEXT_PUBLIC_CURRENT_ENV === envsEnum.PRODUCTION ? metaIndex : 'noindex';

    return (
        <Head>
            {title ? <title>{title}</title> : null}
            {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}

            {metaTitle ? <meta name="title" content={metaTitle} /> : null}

            {metaDesc ? <meta name="description" content={metaDesc} /> : null}

            {metaKeywords ? (
                <meta name="keywords" content={metaKeywords} />
            ) : null}

            {metaRobotsContent ? <meta name="robots" content={metaRobotsContent} /> : null}
        </Head>
    );
};

export default NextHeadTag;
