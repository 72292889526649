import { getAssetClassColor } from '@components/Investor/NewDashboard/helper';
import * as OpportunityHelper from "@ui/helpers/opportunities/helper";
import { OpportunityTypes } from '@ui/helpers/opportunities/opportunity-types';

export const productsSlug = {
    UNLISTED_CORPORATE_DEBT: 'unlisted-corporate-debt',
    VENTURE_DEBT: 'venture-debt',
    INVOICE_DISCOUNTING: 'invoice-discounting',
    ASSET_BACKED_LEASING: 'asset-backed-leasing',
};

export const productItems = [
    {
        title: 'Corporate Debt',
        slug: productsSlug.UNLISTED_CORPORATE_DEBT,
        head: {
            title: 'Corporate Debt (Unlisted)',
            description: '',
            keywords: [],
        },
        banner: {
            bannerImage: '/images/products/corporate-debt-tree.svg', // for v1
            videoSrc:
                'https://altgraaf-public-static-images.s3.ap-south-1.amazonaws.com/product-videos/corporate_debt.mp4',
            coverImage: '/images/products/v2/corporate-debt-cover.png',
            playGtmEventName: 'corporate_debt_video_play',
            pauseGtmEventName: 'corporate_debt_video_pause',
            completeGtmEventName: 'corporate_debt_video_complete',
            irr: '10%-16%',
            tenure: '12-36 Months',
            title: 'Corporate Debt ',
            subtitle: '(unlisted)',
            bannerButton: 'Explore Opportunities',
            productDescription:
                'It is debt taken by corporates in the form of bonds (NCDs – Non-convertible debentures). The NCDs issued are not listed on exchanges. These bonds promise to pay periodic principal and interest payments to the investors over a defined tenure. Companies with proven cashflows and business models explore this route of financing to support their business growth needs.',
        },
        productWhyInvest: {
            title: 'corporate debt opportunity?',
            cards: [
                {
                    title: 'Predictable returns',
                    description: 'Secondary income stream',
                    icon: '/images/products/predictable-returns.svg',
                },
                {
                    title: 'Secured',
                    description: 'Backed by strong collateral',
                    icon: '/images/products/balance-cd.svg',
                },
                {
                    title: 'Diversification',
                    description: 'Fixed return without market volatility',
                    icon: '/images/products/diversification-cd.svg',
                },
                {
                    title: 'Regular Income',
                    description: 'Periodic principal and interest repayments',
                    icon: '/images/products/calendar.svg',
                },
            ],
        },
        productCreditAssessment: {
            listItems: [
                {
                    id: '01',
                    title: 'Background of Company',
                    bulletPoints: [
                        'Track record and credibility of founders and management',
                        'Market Position',
                    ],
                },
                {
                    id: '02',
                    title: 'Financial Strength',
                    bulletPoints: [
                        'Analysis of financial performance',
                        'Ability to repay debt obligations',
                    ],
                },
                {
                    id: '03',
                    title: 'Business Model',
                    bulletPoints: [
                        'Understanding growth projections',
                        'Analysing unit economics',
                    ],
                },
                {
                    id: '04',
                    title: 'Corporate Governance',
                    bulletPoints: [
                        'Track record of compliance',
                        'Strong audit and reporting structures',
                    ],
                },
            ],
        },
        productRisk: {
            cards: [
                {
                    description:
                        'Secured and backed by tangilble assets/guarantees',
                    icon: '/images/products/guarantee.svg',
                },
                {
                    description:
                        'Financial covenants for early warning triggers',
                    icon: '/images/products/trigger.svg',
                },
                {
                    description: 'Due diligence and active asset monitoring',
                    icon: '/images/products/diligence.svg',
                },
            ],
        },
        productRecentDeals: {
            title: 'Past opportunities',
            description:
                'Exciting deals across tenure, asset class, and returns.',
            deals: [
                {
                    id: 0,
                    logo: '/images/homepage/deals/origo.svg',
                    description:
                        'Opportunity to earn attractive returns through ICRA A3 rated Commercial Paper (CP) of Origo Commodities',
                    name: 'Origo Commodities',
                    subscribed: '100% subscribed',
                    tenure: '5 months',
                },
                {
                    id: 1,
                    logo: '/images/homepage/deals/cashe.svg',
                    description: `Investment opportunity to participate in unlisted NCD issued by NBFC arm of CASHe (Bhanix Finance & Investment Limited)`,
                    name: 'Bhanix Finance',
                    subscribed: '100% subscribed',
                    tenure: '12 months',
                },
                {
                    id: 2,
                    logo: '/images/homepage/deals/kns.svg',
                    description:
                        'KNS Infra is Bangalore’s one of the leading plotted developer enterprise with over 15 years of experience',
                    name: 'KNS Infra',
                    subscribed: '100% subscribed',
                    tenure: '17 months',
                },
                {
                    id: 3,
                    logo: '/images/homepage/deals/homecredit.svg',
                    description:
                        'Investment in unlisted NCD of HOME CREDIT - Part of a leading global consumer finance provider established in 1997',
                    name: 'Home Credit',
                    subscribed: '100% subscribed',
                    opportunityName: 'Corporate Debt (Unlisted)',
                    opportunityType: OpportunityHelper.getCorporateDebtOpportunity(),
                    grossReturns: '12%',
                    tenure: '13 months',
                },
                {
                    id: 4,
                    logo: '/images/homepage/deals/rupeek.svg',
                    description:
                        "Investment opportunity to participate in Rupeek, one of India's largest and fastest-growing Gold Loan Platform",
                    name: 'Rupeek',
                    subscribed: '100% subscribed',
                    tenure: '374 days',
                },
                {
                    id: 5,
                    logo: '/images/homepage/deals/navi.svg',
                    description:
                        'Navi group is a financial services company.',
                    name: 'Navi',
                    subscribed: '100% subscribed',
                    tenure: '24 months',
                },
            ],
        },
        productFaqs: {
            title: 'Frequently Asked Questions',
            bgImage: '/images/products/faq.svg',
        },
    },
    {
        title: 'Venture Debt',
        slug: productsSlug.VENTURE_DEBT,
        navColor: getAssetClassColor(OpportunityTypes.VENTURE_DEBT),
        head: {
            title: 'Venture Debt',
            description:
                'Financing fundamentally sound growth-stage companies to greater heights',
            keywords: [],
        },
        banner: {
            bannerImage: '/images/products/venture-debt-tree.png', // for v1
            videoSrc:
                'https://altgraaf-public-static-images.s3.ap-south-1.amazonaws.com/product-videos/venture_debt.mp4',
            coverImage: '/images/products/v2/venture-debt-cover.png',
            playGtmEventName: 'venture_debt_video_play',
            pauseGtmEventName: 'venture_debt_video_pause',
            completeGtmEventName: 'venture_debt_video_complete',
            irr: '15%-18%',
            tenure: '12-24 Months',
            title: 'Venture Debt',
            bannerButton: 'Explore Opportunities',
            productDescription:
                'Unlisted corporate debt raised by new-age companies/start-ups. These companies are still in the early stages of growth and raise venture debt to supplement equity capital.',
        },
        productWhyInvest: {
            title: 'venture debt opportunity?',
            cards: [
                {
                    title: 'High yield',
                    description: 'and low tenure',
                    icon: '/images/products/diversification-cd.svg',
                },
                {
                    title: 'Participate in',
                    description: 'the growth journey of a start-up',
                    icon: '/images/products/graph-cd.svg',
                },
                {
                    title: 'Balanced',
                    description: 'risk-adjusted return',
                    icon: '/images/products/balance-cd.svg',
                },
                {
                    title: 'Co-invest alongside',
                    description: 'marquee investors',
                    icon: '/images/products/users.svg',
                },
            ],
        },
        productCreditAssessment: {
            listItems: [
                {
                    id: '01',
                    title: 'Background of Founders',
                    bulletPoints: [
                        'Track record and credibility of founders',
                        'Team building ability',
                    ],
                },
                {
                    id: '02',
                    title: 'Unit Economics',
                    bulletPoints: [
                        'Analysis of margins and cost structure',
                        'Sustainability and scalability of business',
                    ],
                },
                {
                    id: '03',
                    title: 'Growth Potential',
                    bulletPoints: [
                        'Ability to capture market share',
                        'Capital raising ability',
                    ],
                },
                {
                    id: '04',
                    title: 'Corporate Governance',
                    bulletPoints: [
                        'Track record of compliance',
                        ' Strong audit and reporting structures',
                    ],
                },
            ],
        },
        productRisk: {
            cards: [
                {
                    description: 'Proven ability to scale',
                    icon: '/images/products/scale.svg',
                },
                {
                    description: 'Support from institutional capital',
                    icon: '/images/products/trigger.svg',
                },
                {
                    description: 'Due diligence and active asset monitoring',
                    icon: '/images/products/diligence.svg',
                },
            ],
        },
        productRecentDeals: {
            title: 'Past opportunities',
            description:
                'Exciting deals across tenure, asset class, and returns.',
            deals: [
                {
                    id: 0,
                    logo: '/images/homepage/deals/echargeup.svg',
                    description:
                        'Providing Battery as a Service through EV Charging Network',
                    name: 'eChargeUp',
                    subscribed: '100% subscribed',
                    tenure: '15 months',
                },
                {
                    id: 1,
                    logo: '/images/homepage/deals/vilcart.svg',
                    description: `Participate in the Vilcart, building tech led rural commerce`,
                    name: 'Vilcart',
                    subscribed: '100% subscribed',
                    tenure: '15 months',
                },
            ],
        },
        productFaqs: {
            title: 'Frequently Asked Questions',
            bgImage: '/images/products/faq.svg',
        },
    },
    {
        title: 'Invoice Discounting',
        slug: productsSlug.INVOICE_DISCOUNTING,
        navColor: getAssetClassColor(OpportunityTypes.INVOICE_DISCOUNTING),
        head: {
            title: 'Invoice Discounting',
            description:
                'Bridging the gap in working capital needs of companies.',
            keywords: [],
        },
        banner: {
            bannerImage: '/images/products/invoice-discounting-tree.png', // for v1
            videoSrc:
                'https://altgraaf-public-static-images.s3.ap-south-1.amazonaws.com/product-videos/invoice_discounting.mp4',
            coverImage: '/images/products/v2/invoice-discounting-cover.png',
            playGtmEventName: 'invoice_discounting_video_play',
            pauseGtmEventName: 'invoice_discounting_video_pause',
            completeGtmEventName: 'invoice_discounting_video_complete',
            irr: '10%-14%',
            tenure: '1-6 Months',
            title: 'Invoice Discounting',
            bannerButton: 'Explore Opportunities',
            productDescription:
                'It is a form of financing chosen by businesses to tap into their unpaid invoices so that they can meet their working capital requirements. Essentially, it is post-sales funding. Businesses avail financing towards unpaid invoices for the goods already delivered at a pre-determined cost.',
        },
        productWhyInvest: {
            title: 'invoice discounting opportunity?',
            question: 'Why invest in an',
            cards: [
                {
                    title: 'Short tenure with good yields',
                    description: 'helps boost your investment portfolio',
                    icon: '/images/products/graph.svg',
                },
                {
                    title: 'Exposure to high quality companies',
                    description: 'making the risk reward favourable',
                    icon: '/images/products/balance.svg',
                },
                {
                    title: 'Diversify your portfolio',
                    description: 'across different industries and sectors',
                    icon: '/images/products/diversification.svg',
                },
                {
                    title: 'Range of products',
                    description:
                        'with security structures tailored to your diverse needs',
                    icon: '/images/products/5-start.svg',
                },
            ],
        },
        invoiceRiskFactors: {
            investmentRisks: {
                title: 'Investment Risks',
                risks: [
                    {
                        title: 'Credit Risk: Inability of the borrower to repay promised amount.',
                        icon: '/images/products/credit-risk.svg',
                    },
                    {
                        title: 'Liquidity Risk: Ability to liquidate before the maturity of the investment.',
                        icon: '/images/products/liquidity-risk.svg',
                    },
                    {
                        title: 'Fraud Risk: Wilful default on payments by borrowers.',
                        icon: '/images/products/fraud-risk.svg',
                    },
                ],
            },
            riskMitigation: {
                title: 'Risk Mitigation Factors',
                mitigation: [
                    {
                        title: 'Bank Guarantee (BG) or Trade Credit Insurance Protection*',
                        icon: '/images/products/insurance.svg',
                    },
                    {
                        title: 'Escrow Control on client repayments*',
                        icon: '/images/products/escrow.svg',
                    },
                    {
                        title: 'Post-dated Cheques (PDC) or Undated Cheques (UDC)',
                        icon: '/images/products/cheque.svg',
                    },
                    {
                        title: 'Credit comfort from underlying trades and Strong transaction history',
                        icon: '/images/products/user-secure.svg',
                    },
                ],
            },
            note: '* Limited to certain Invoice Discounting Products only',
        },
        invoiceProductInfo: {
            title: 'Our Invoice Discounting Products tailored to your diverse needs',
            brands: [
                {
                    id: 'altSmart',
                    logo: '/images/brands/altSmart.svg',
                    title: 'Invest confidently with a bank guarantee',
                    bgColor: 'bg-altSmart-bg-50',
                    description: (
                        <>
                            Your investment is{' '}
                            <span className="p5-semibold">
                                100% secured by Bank Guarantee (BG)
                            </span>{' '}
                            and all repayments are made via altGraaf controlled
                            escrow account.
                        </>
                    ),
                    topLevelDetails: [
                        {
                            label: 'IRR',
                            value: '10%-11%',
                        },
                        {
                            label: 'Internal Risk Rating',
                            value: 'Very Low',
                        },
                    ],
                    learnMoreLink: '',
                    faqs: [
                        {
                            id: 1,
                            question: 'What does bank guarantee mean?',
                            answer: (
                                <>
                                    A bank guarantee is an enforceable
                                    commitment by a bank to ensure payment to
                                    the beneficiary if the applicant fails to
                                    fulfill their contractual obligations in a
                                    trade transaction.
                                </>
                            ),
                        },
                        {
                            id: 2,
                            question:
                                'Does the bank guarantee secure my full investment?',
                            answer: (
                                <>
                                    Yes, Bank Guarantee protection covers 100%
                                    of both Principal and Interest.
                                </>
                            ),
                        },
                        {
                            id: 3,
                            question:
                                'Does altGraaf have escrow account control on altSmart product?',
                            answer: (
                                <>
                                    Yes. All repayments from clients (buyer of
                                    goods) tied to the invoices will come to an
                                    escrow account controlled by altGraaf to
                                    repay the investors.
                                </>
                            ),
                        },
                        {
                            id: 5,
                            question:
                                'How and when will the bank guarantee be used?',
                            answer: (
                                <ul className="flex flex-col gap-2">
                                    <li>
                                        • In the event of delay/default, the
                                        creditor/beneficiary shall initiate the
                                        process of invoking the bank guarantee
                                        within the validity period mentioned in
                                        the guarantee document (typically within
                                        20 business days)
                                    </li>
                                    <li>
                                        • The bank processes the payments within
                                        5 business days post verification
                                    </li>
                                </ul>
                            ),
                        },
                        {
                            id: 6,
                            question:
                                'What are some of the risks associated with bank guarantee claims?',
                            answer: (
                                <ul className="flex flex-col gap-2">
                                    <li>
                                        • Fraud: Authenticity or validity of
                                        transaction documents
                                    </li>
                                    <li>
                                        • Operational: Errors that can lead to
                                        bank guarantee claim rejection
                                    </li>
                                    <li>
                                        • Legal: Discrepancies in the presented
                                        documents
                                    </li>
                                    <li>
                                        • Bank: Issuing bank faces financial
                                        difficulties
                                    </li>
                                </ul>
                            ),
                        },
                    ],
                },
                {
                    id: 'altArmour',
                    logo: '/images/brands/altArmour.svg',
                    title: 'Balance risk with insurance protection',
                    bgColor: ' bg-altArmour-bg-50',
                    description: (
                        <>
                            Your investment is{' '}
                            <span className="p5-semibold">
                                secured by Trade Credit Insurance
                            </span>{' '}
                            and all repayments are made via altGraaf controlled
                            escrow account.
                        </>
                    ),
                    topLevelDetails: [
                        {
                            label: 'IRR',
                            value: '11%-12%',
                        },
                        {
                            label: 'Internal Risk Rating',
                            value: 'Low',
                        },
                    ],
                    learnMoreLink: '',

                    faqs: [
                        {
                            id: 1,
                            question: 'What is Trade Credit Insurance (TDI)?',
                            answer: (
                                <>
                                    TDI assures investment protection and
                                    reduces losses due to non- repayment of
                                    trade debts on occurrence of a covered
                                    event.
                                </>
                            ),
                        },
                        {
                            id: 2,
                            question:
                                'Does the Trade Credit Insurance protect my full investment?',
                            answer: (
                                <>
                                    While the purchased insurance covers the
                                    entire investment amount plus the interest,
                                    in the event of delay/default the final
                                    approved claim by the insurance company
                                    could be equal to or lower than 100% of full
                                    principal and interest.
                                </>
                            ),
                        },
                        {
                            id: 3,
                            question:
                                'Does altGraaf have escrow account control on altArmour product?',
                            answer: (
                                <>
                                    Yes. All repayments from clients (buyer of
                                    goods) tied to the invoices will come to an
                                    escrow account controlled by altGraaf to
                                    repay the investors.
                                </>
                            ),
                        },
                        {
                            id: 5,
                            question: 'How and when will the TDI be used?',
                            answer: (
                                <ul className="flex flex-col gap-2">
                                    <li>
                                        In the event of default, the following
                                        steps will be undertaken to invoke
                                        insurance claim and process the
                                        repayment
                                    </li>
                                    <li className="ml-2">
                                        • Request: Formal claim request is made
                                        to insurance company within 3 months
                                        post the intended repayment date with
                                        all required documents
                                    </li>
                                    <li className="ml-2">
                                        • Assessment: Insurer reviews the claim
                                        based on policy terms, verifying buyer
                                        default and insured amount
                                    </li>
                                    <li className="ml-2">
                                        • Settlement: Valid claims are settled
                                        by the Insurer, reimbursing up to the
                                        agreed % of the loss. This could take
                                        between 3 – 6 months post claim
                                        submission
                                    </li>
                                </ul>
                            ),
                        },
                        {
                            id: 6,
                            question:
                                'What are some of the risks associated with insurance claims?',
                            answer: (
                                <>
                                    <ul className="flex flex-col gap-2">
                                        <li>
                                            • Disputed Balance: Credit insurance
                                            providers may limit policy payout if
                                            the balance is in dispute by the
                                            debtor
                                        </li>
                                        <li>
                                            • Coverage Denial: Insurance
                                            policies are subject to conditions,
                                            including exclusion of externalities
                                        </li>
                                        <li>
                                            • Lower Settlement: The Insurer may
                                            provide lower settlement offers,
                                            which could be lower than 100% of
                                            Principal & Interest
                                        </li>
                                        <li>
                                            • Claim Period: Insurance
                                            settlements take time, and it may
                                            take 3 - 6 months post claim
                                            submission
                                        </li>
                                    </ul>
                                </>
                            ),
                        },
                    ],
                },
                {
                    id: 'altBlu',
                    logo: '/images/brands/altBlu.svg',
                    title: 'Unlock growth with established companies',
                    bgColor: 'bg-altBlu-bg-50',
                    description: (
                        <>
                            Invest in invoices raised by{' '}
                            <span className="p5-semibold">
                                blue chip or established companies
                            </span>{' '}
                            and all repayments are made via an altGraaf
                            controlled escrow account. 
                        </>
                    ),
                    topLevelDetails: [
                        {
                            label: 'IRR',
                            value: '11%-12.5%',
                        },
                        {
                            label: 'Internal Risk Rating',
                            value: 'Medium',
                        },
                    ],
                    learnMoreLink: '',

                    faqs: [
                        {
                            id: 1,
                            question: 'What does blue chip mean?',
                            answer: (
                                <>
                                    This means that the invoices are raised by
                                    very large established companies with a
                                    significant repayment track record. Most of
                                    these companies are traded on the stock
                                    exchanges.
                                </>
                            ),
                        },
                        {
                            id: 2,
                            question:
                                'Does altBlu investment carry BG or TCI protection?',
                            answer: (
                                <>
                                    altBlu investments do NOT carry bank
                                    guarantee protection. Some of the altBlu
                                    investment opportunities do carry Trade
                                    Credit Insurance that secures up to 90% of
                                    the principal investment in the case of
                                    default. If an investment carries insurance,
                                    it will be communicated accordingly along
                                    with the amount of coverage.
                                </>
                            ),
                        },
                        {
                            id: 3,
                            question:
                                'Does altGraaf have escrow account control on altBlu product?',
                            answer: (
                                <>
                                    Yes. All repayments from clients (buyer of
                                    goods) tied to the invoices will come to an
                                    escrow account controlled by altGraaf to
                                    repay the investors.
                                </>
                            ),
                        },
                        {
                            id: 5,
                            question:
                                'What are the risks associated with investing in altBlu product?',
                            answer: (
                                <ul className="flex flex-col gap-2">
                                    <li>
                                        • If the investment is partially secured
                                        with insurance, any claims on default is
                                        limited to maximum insured or approved
                                        claim amount
                                    </li>
                                    <li>
                                        • The investment is Unsecured if it does
                                        NOT carry any Insurance or BG protection
                                    </li>
                                    <li>
                                        • General Invoice Discounting product
                                        category investment risks remain
                                    </li>
                                </ul>
                            ),
                        },
                    ],
                },
                {
                    id: 'altPack',
                    logo: '/images/brands/altPack.svg',
                    title: 'Accelerate returns through diversified investing',
                    bgColor: 'bg-altPack-bg-50',
                    description: (
                        <>
                            Your investment is{' '}
                            <span className="p5-semibold">
                                diversified against a pool of invoices
                            </span>{' '}
                            with escrow control on repayments.
                        </>
                    ),
                    topLevelDetails: [
                        {
                            label: 'IRR',
                            value: '10%-13%',
                        },
                        {
                            label: 'Internal Risk Rating',
                            value: 'Very Low – High (varies by pool)',
                        },
                    ],
                    learnMoreLink: '',
                    faqs: [
                        {
                            id: 1,
                            question: 'What does pool of invoices mean?',
                            answer: (
                                <>
                                    This means that the underlying investment is
                                    made against a basket of
                                    receivables/invoices from various
                                    customers/buyers-of-goods. This ensures
                                    diversification and reduces risk. Post
                                    investment, units from the pool shall be
                                    transferred to the demat account of the
                                    Investor.
                                </>
                            ),
                        },
                        {
                            id: 2,
                            question:
                                'Is there escrow control on repayments in altPack product?',
                            answer: (
                                <>
                                    Yes. All repayments from clients (buyer of
                                    goods) tied to the invoices will come to an
                                    escrow account controlled by an assigned
                                    SEBI registered Trustee to repay the
                                    investors.
                                </>
                            ),
                        },
                        {
                            id: 3,
                            question:
                                'What is Credit Risk Protection coverage?',
                            answer: (
                                <>
                                    Let us assume a given investment carries 15%
                                    Credit Risk Protection on investment. This
                                    means that in the event of default, 15% of
                                    initial losses is covered by the originator
                                    of the pooled instrument with the remaining
                                    risk being borne by the investor.
                                </>
                            ),
                        },
                        {
                            id: 5,
                            question:
                                'What are some of the risks associated with insurance claims?',
                            answer: (
                                <ul>
                                    <li>
                                        • If the investment carries credit risk
                                        protection, the investment is partially
                                        protected up to the offered coverage
                                    </li>
                                    <li>
                                        • The investment is Unsecured if it does
                                        NOT carry any Insurance or BG or Credit
                                        Risk Protection
                                    </li>
                                    <li>
                                        • General Invoice Discounting product
                                        category investment risks remain
                                    </li>
                                </ul>
                            ),
                        },
                    ],
                },
                {
                    id: 'altWings',
                    logo: '/images/brands/altWings.svg',
                    title: 'Soar with high returns',
                    bgColor: 'bg-altWings-bg-50',
                    description: (
                        <>
                            Invest in established and upcoming companies{' '}
                            <span className="p5-semibold">
                                across varied industries and sectors
                            </span>{' '}
                            . The investment is Unsecured.
                        </>
                    ),
                    topLevelDetails: [
                        {
                            label: 'IRR',
                            value: '12%-14%',
                        },
                        {
                            label: 'Internal Risk Rating',
                            value: 'Very high',
                        },
                    ],
                    learnMoreLink: '',
                    faqs: [
                        {
                            id: 1,
                            question: 'How does the transaction work?',
                            answer: (
                                <>
                                    The seller/borrower/vendor sells goods to
                                    client/anchor/buyer. The client raises an
                                    invoice and promises to pay at a future
                                    date. The borrower discounts the invoices
                                    with investors today for a defined tenure
                                    and return. The Buyer pays the Seller on the
                                    promised due date, and the seller then
                                    transfers to investors via a regular
                                    collection escrow account.
                                </>
                            ),
                        },
                        {
                            id: 2,
                            question:
                                'Is there escrow control on repayments on altWings product?',
                            answer: (
                                <>
                                    No. All repayments from clients (buyer of
                                    goods) tied to the invoices will be paid to
                                    Seller/Vendor who then distributes to
                                    investors.
                                </>
                            ),
                        },
                        {
                            id: 3,
                            question:
                                'Does altWings investment carry BG or TCI or Credit protection?',
                            answer: (
                                <>
                                    altWings investments are Unsecured and do
                                    NOT carry any investment protection like
                                    bank guarantee or TCI or Credit Coverage
                                    protection. While all risk mitigation
                                    factors are adopted to reduce risk, the
                                    nature of the product is unsecured.
                                </>
                            ),
                        },
                        {
                            id: 5,
                            question:
                                'What are the risks associated with investing in altWings product?',
                            answer: (
                                <ul>
                                    <li>• Unsecured</li>
                                    <li>
                                        • No Escrow Account Control by altGraaf
                                    </li>
                                    <li>
                                        • Co-mingling risk exist where the
                                        Seller collects the money and then
                                        passes the same to the investor
                                    </li>
                                    <li>
                                        • General Invoice Discounting product
                                        category investment risks remain
                                    </li>
                                </ul>
                            ),
                        },
                    ],
                },
            ],
        },
        productCreditAssessment: {
            listItems: [
                {
                    id: '01',
                    title: 'Credibility of Parties Involved',
                    bulletPoints: [
                        'Prior records on raised invoices & timely payments',
                        'Credit history of the parties involved',
                    ],
                },
                {
                    id: '02',
                    title: 'Verification of Invoices',
                    bulletPoints: [
                        'Authenticity of the underlying invoices',
                        'Validate delivery of goods/services',
                    ],
                },
                {
                    id: '03',
                    title: 'Analysing Working Capital Cycles',
                    bulletPoints: [
                        'Understanding ageing of receivables and payables',
                        'Determining cash realisation cycles',
                    ],
                },
                {
                    id: '04',
                    title: 'Assessing Payment Flows',
                    bulletPoints: [
                        'Cash control mechanisms such as an escrow account',
                        'Visibility of bank accounts',
                    ],
                },
            ],
        },
        productRisk: {
            cards: [
                {
                    description:
                        'Participate with financially strong companies',
                    icon: '/images/products/user-with-flag.svg',
                },
                {
                    description: 'Credit comfort from underlying trades',
                    icon: '/images/products/lens.svg',
                },
                {
                    description: 'Strong transaction history',
                    icon: '/images/products/diligence.svg',
                },
            ],
        },
        productRecentDeals: {
            title: 'Past opportunities',
            description:
                'Exciting deals across tenure, asset class, and returns.',
            deals: [
                {
                    id: 0,
                    logo: '/images/homepage/deals/bira.svg',
                    description: `Bira is a modern beer brand with presence across 15 countries`,
                    name: 'Bira',
                    subscribed: '100% subscribed',
                    tenure: '90 days',
                },
                {
                    id: 1,
                    logo: '/images/homepage/deals/Aris.svg',
                    description:
                        'Aris Infra is a technology based B2B platform providing a wide array of building material products across the construction life cycle.',
                    name: 'Aris Infra',
                    subscribed: '100% subscribed',
                    tenure: '2 months',
                },
                {
                    id: 2,
                    logo: '/images/homepage/deals/zetwerk.svg',
                    description:
                        'Zetwerk is a B2B manufacturing marketplace. It is one of the fastest Unicorn currently valued at $2.7 Billion',
                    name: 'Zetwerk',
                    subscribed: '100% subscribed',
                    tenure: '90 days',
                },
                {
                    id: 3,
                    logo: '/images/homepage/deals/cocoblu.svg',
                    description:
                        'Cocoblu is one of the largest E-commerce sellers on Amazon, partnered with top brands across multiple categories.',
                    name: 'Cocoblu',
                    subscribed: '100% subscribed',
                    tenure: '3 months',
                },
                {
                    id: 4,
                    logo: '/images/homepage/deals/buyume.svg',
                    description:
                        'Buyume is a social commerce platform enabling beauty professionals who also act as resellers to sell beauty products, and provide technical guidance targeting Tier 2 and 3 cities.',
                    name: 'Buyume',
                    subscribed: '100% subscribed',
                    tenure: '31 days',
                },
                {
                    id: 5,
                    logo: '/images/homepage/deals/letstransport.svg',
                    description:
                        'Largest logistics platform for goods distribution connecting mini-truckers to enterprises like Dabur, Amazon',
                    name: "Let's Transport",
                    subscribed: '100% subscribed',
                    tenure: '91 days',
                },
            ],
        },
        productFaqs: {
            title: 'Frequently Asked Questions',
            bgImage: '/images/products/faq.svg',
        },
    },
    {
        title: 'Asset Backed Leasing',
        slug: productsSlug.ASSET_BACKED_LEASING,
        navColor: getAssetClassColor(OpportunityTypes.ASSET_BACKED_LEASING),
        head: {
            title: 'Asset Backed Leasing',
            description: '',
            keywords: [],
        },
        banner: {
            bannerImage: '/images/products/asset-backed-leasing-tree.png', // for v1
            videoSrc:
                'https://altgraaf-public-static-images.s3.ap-south-1.amazonaws.com/product-videos/asset_leasing.mp4',
            coverImage: '/images/products/v2/asset-leasing-cover.png',
            playGtmEventName: 'asset_backed_leasing_video_play',
            pauseGtmEventName: 'asset_backed_leasing_video_pause',
            completeGtmEventName: 'asset_backed_leasing_video_complete',
            irr: '14%-22%',
            tenure: '24-48 Months',
            title: 'Asset Backed Leasing',
            bannerButton: 'Explore Opportunities',
            productDescription:
                'In simple terms, leasing is renting an asset by its owner to a lessee (person who takes the asset on rent) for a specific period of time to earn rental income. Assets that can be leased range from land, property, vehicles, and machines to even the smallest artifacts. The most common example is the leasing of a house to a tenant by a landlord.',
        },
        productWhyInvest: {
            title: 'Asset Backed Leasing?',
            cards: [
                {
                    title: 'Predictable returns',
                    description: 'Secondary source of income',
                    icon: '/images/products/predictable-returns.svg',
                },
                {
                    title: 'Asset ownership',
                    description: 'Higher security due to defined ownership',
                    icon: '/images/products/balance-cd.svg',
                },
                {
                    title: 'Diversify your portfolio',
                    description: 'Ownership of different hard assets',
                    icon: '/images/products/diversification-cd.svg',
                },
                {
                    title: 'Earn high yields',
                    description: 'With visible repayments',
                    icon: '/images/products/graph-cd.svg',
                },
            ],
        },
        productCreditAssessment: {
            listItems: [
                {
                    id: '01',
                    title: 'Background of Lesee',
                    bulletPoints: [
                        'Track record and credibility of promoters and management',
                        'Operating history',
                    ],
                },
                {
                    id: '02',
                    title: 'Understanding of the Asset',
                    bulletPoints: [
                        'Analysis of asset life and maintenance',
                        'Understanding of resale price of the asset',
                    ],
                },
                {
                    id: '03',
                    title: 'Business Model',
                    bulletPoints: [
                        'Understanding asset utilisation levels',
                        'Analysing unit economics',
                    ],
                },
                {
                    id: '04',
                    title: 'Corporate Governance',
                    bulletPoints: [
                        'Track record of compliance',
                        'Strong audit and reporting structures',
                    ],
                },
            ],
        },
        productRisk: {
            cards: [
                {
                    description:
                        'Clear identification and ownership of the asset',
                    icon: '/images/products/user-with-flag.svg',
                },
                {
                    description: 'Predetermined resale value of the asset',
                    icon: '/images/products/lens.svg',
                },
                {
                    description: 'Due diligence and active asset monitoring',
                    icon: '/images/products/diligence.svg',
                },
            ],
        },
        productRecentDeals: {
            title: 'Past opportunities',
            description:
                'Exciting deals across tenure, asset class, and returns.',
            deals: [],
        },
        productFaqs: {
            title: 'Frequently Asked Questions',
            bgImage: '/images/products/faq.svg',
        },
    },
];
