/* Generic enums */

export const brandNames = Object.freeze({
    ALT_PACK: 'altPack',
    ALT_BLU: 'altBlu',
    ALT_SMART: 'altSmart',
    ALT_ARMOUR: 'altArmour',
    ALT_WINGS: 'altWings',
});

export const envsEnum = Object.freeze({
    PRODUCTION: "production",
    DEVELOPMENT: "development",
    QA: "qa",
    QAMOBILE: "qamobile",
    UAT: "uat",
    UATMOBILE: "uatmobile"
});