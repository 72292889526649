import { WalletName, brandName } from '@helpers/constants';
import { productsSlug } from '@helpers/productsHelper';

// use '/[pages route]' for pages and #[componentName] for components.
export const HeadTagData = {
    '/about-us': {
        title: `About Us | ${brandName}`,
        metaTitle: `About Us | ${brandName}`,
        metaDesc: `Discover ${brandName} - Learn about our founders, leadership team, vision, mission, and values. With ${brandName}, explore innovative alternative fixed-income investment products and empower your financial future. `,
        metaKeywords:
            'alternative investment platform, best alternative investment platform in india, high quality investments, online investment, investment alternatives in india, altgraaf, wealth creation, fixed income opportunities, about altgraaf, altgraaf funding investors',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/about-us`,
        metaIndex: 'noindex',
    },
    '/refer-and-earn': {
        title: `Refer & Earn`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/dashboard': {
        title: `Dashboard | ${brandName}`,
        metaDesc: `All your investments at one place on ${brandName} wealth platform! View your complete portfolio of fixed income instruments with ${brandName}.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
    },
    '/opportunities': {
        title: `Investment Opportunities | ${brandName}`,
        metaDesc: `Explore fixed income investment opportunities on ${brandName} that offers returns up to 20% IRR. Discover a world of high-yield fixed income investing.`,
        metaKeywords:
            'Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/': {
        canonicalUrl: process.env.NEXT_PUBLIC_APP_URL,
        metaDesc: `${brandName} is the best online investment platform for alternative fixed-income investments. Explore Unlisted Corporate Bonds (Non-Convertible Debentures - NCD), Commercial Papers, Venture Debt, Asset-backed Leasing, and Invoice Discounting.`,
        title: `Alternative Fixed Income Investments | ${brandName}`,
        metaTitle: `Alternative Fixed Income Investments | ${brandName}`,
        metaKeywords:
            'altgraaf, best fixed income investments in india, investment opportunities in india, investing in bonds, best assets to invest in india, fixed income, best investment options in india, best invoice discounting platform in india, alternative investment opportunities, alternative fixed income investments',
    },
    '/signup': {
        title: `Sign-Up to invest in Alternative Fixed Income Opportunities | ${brandName}`,
        metaTitle: `Sign-Up to invest in Alternative Fixed Income Opportunities | ${brandName}`,
        metaDesc: `Sign up with ${brandName} and invest in exclusively curated non-market linked fixed-income investment opportunities in India. Earn better and more predictable returns than traditional investment options.`,
        metaKeywords:
            'investment online, manage investments easily, low risk investments, safe investments, alternate investments, high returns, optimise risks, easy investments, investments in India, investment opportunities, alternative investments, high yield, alternate assets, compound your returns',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/signup`,
        metaIndex: 'noindex',
    },
    '/login': {
        title: `Login`,
        metaDesc: `Welcome to the world of high return investment opportunities. Now diversify your portfolio beyond traditional assets and unlock great returns`,
        metaKeywords:
            'investment online, manage investments easily, low risk investments, safe investments, alternate investments, high returns, optimise risks, easy investments, investments in India, investment opportunities, alternative investments, high yield, alternate assets, compound your returns',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/login`,
    },
    '/invitation-accept': {
        title: `Complete Account Activation`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/invitation-confirm': {
        title: `Verify Account Details`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/team': {
        title: `Meet the team | ${brandName}`,
        metaTitle: `Meet the team | ${brandName}`,
        metaDesc: `Meet the people behind ${brandName} (an alternative fixed-income investment platform) who bring great investment opportunities to clients via an easy-to-use digital platform. ${brandName} offers opportunities across various products that deliver high risk adjusted returns through its robust credit risk assessment process.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/team`,
    },
    '/credit-evaluation-process': {
        title: `Credit Assessment Process | Alternate Fixed Income Investments | ${brandName}`,
        metaTitle: `Credit Assessment Process | Alternate Fixed Income Investments | ${brandName}`,
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/credit-evaluation-process`,
        metaDesc: `Explore ${brandName} robust credit evaluation process. Invest in India’s first horizontal platform for alternative fixed-income investments. Unlock high returns and non-market-linked investment opportunities with ${brandName}. `,
        metaIndex: 'noindex',
        metaKeywords: 'what is credit risk, credit risk assessment, importance of credit risk management, what is credit risk analysis, business model validation, altgraaf, high quality investments, financial health assessment, alternative investments',
    },
    '/accounts': {
        title: `Accounts | ${brandName}`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords: `Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details`,
    },
    '/profile': {
        title: `Profile | ${brandName}`,
        metaDesc:
            'Investor Profile Details. The opportunities to build generational wealth have always remained with those with generational wealth. We are striving to bridge the long-existing accessibility gap to such high-hanging fruits of investment for the common investor.',
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/kyc': {
        title: `KYC`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/dashboard/my-investments': {
        title: 'My Investments',
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/dashboard/reinvestments': {
        title: 'Reinvestments',
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/dashboard/reinvestments/[reinvestmentLineId]': {
        title: 'Reinvestments',
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/dashboard/my-investments/[investmentId]': {
        title: 'Investment Details',
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/create-new-account': {
        title: `Create New Account | ${brandName}`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/contact-us': {
        title: `Contact Us | ${brandName}`,
        metaDesc: `Diversify your investment portfolio with alternative investments. ${brandName}'s product offerings include Corporate Bonds, Venture Debt, Asset-backed Leasing, Invoice Discounting and Market-Linked Debentures (MLD).`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/contact-us`,
    },
    '/careers': {
        title: `Careers | ${brandName}`,
        metaTitle: `Careers | ${brandName}`,
        metaDesc: ` Join the exciting ${brandName} team and create a significant impact in the alternative fixed-income industry. Several exciting career opportunities are available across various functions.`,
        metaKeywords:
            'jobs at altgraaf, altgraaf, careers, job opportunities, finance jobs, job search, product jobs, software engineering jobs, fintech jobs, startup jobs',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/careers`,
        metaIndex: 'noindex',
    },
    '/faq': {
        title: `Frequently Asked Questions | ${brandName}`,
        metaTitle: `Frequently Asked Questions | ${brandName}`,
        metaDesc: `View frequently asked questions (FAQs) by our investors about investing in ${brandName}. Get answers on investing in fixed-income debt investment opportunities and get best returns on investment.`,
        metaKeywords:
            'best platform to invest, investment for nri, what is kyc verification, how to start investing, what is irr, types of invoice financing, what is demat account number, altgraaf',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/faq`,
    },
    [`/products/${productsSlug.UNLISTED_CORPORATE_DEBT}`]: {
        title: `Unlisted Corporate Debt | ${brandName}`,
        metaTitle: `Unlisted Corporate Debt | ${brandName}`,
        metaDesc: `Explore the best investment options in India on ${brandName} with corporate bonds that are secured by collateral, paying periodic principal and interest payments to investors over a defined tenure. Earn 10-16% high returns.`,
        metaKeywords:
            'what are bonds, buy bonds in india, online bonds, buy rated bonds, best bonds in india, invest in corporate bonds, types of bonds, what is corporate debt, how to invest in bonds, senior secured bonds',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/products/${productsSlug.UNLISTED_CORPORATE_DEBT}`,
    },
    [`/products/${productsSlug.VENTURE_DEBT}`]: {
        title: `Venture Debt Investment | ${brandName}`,
        metaTitle: `Venture Debt Investment | ${brandName}`,
        metaDesc: `Invest in venture debt, which is corporate debt raised by new-age companies and start-ups that offer high yields backed by secured collateral. Invest alongside marquee investors. Earn 15-18% high returns.`,
        metaKeywords:
            'venture debt, venture debt funds, venture debt in India, venture debt platform, venture debt market, debt funding for startups, venture debt for startups, what is venture debt, high yield fixed income',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/products/${productsSlug.VENTURE_DEBT}`,
    },
    [`/products/${productsSlug.INVOICE_DISCOUNTING}`]: {
        title: `Invest in diverse Invoice Discounting Products | ${brandName}`,
        metaTitle: `Invest in Invoice Discounting | ${brandName}`,
        metaDesc: `${brandName} is the best invoice discounting platform in India. Explore invoice discounting investment opportunities with fully secured bank guarantee (BG), insurance protection and escrow control where businesses try to meet their working capital requirements by tapping into their unpaid invoices. Earn 10-14% returns.`,
        metaKeywords:
            'invoice discounting, what is invoice discounting, discount invoices with insurance protection, best invoice discounting platform in India, bank guarantee (BG) backed invoice discounting, basket of invoices, pool of invoices, credit insurance protection, bill discounting, passive income, high yield fixed returns, best short term investment',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/products/${productsSlug.INVOICE_DISCOUNTING}`,
    },
    [`/products/${productsSlug.ASSET_BACKED_LEASING}`]: {
        title: `Asset Backed Leasing | ${brandName}`,
        metaTitle: `Asset Backed Leasing | ${brandName}`,
        metaDesc: `Invest in asset leasing investment opportunities on ${brandName} where businesses rent assets such as equipment, property, vehicles, machines, etc., for lease to earn rental income. Earn 14-22% returns and investment is secured by the underlying asset.`,
        metaKeywords:
            'asset leasing, asset backed leasing in india, asset leasing investment, financial leasing, asset based leasing, asset leasing company, online investment, lease income, monthly returns',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/products/${productsSlug.ASSET_BACKED_LEASING}`,
    },
    '/opportunity/[id]/fund-declaration': {
        title: `Fund Declaration`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/reinvest-preference': {
        title: `Reinvest Preference`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/disclaimer': {
        title: `Disclaimer`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/investment': {
        title: `Investment`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/investment/review': {
        title: `Review Document`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/investment/profile-details': {
        title: `Profile Details`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/investment/esign': {
        title: `Esign Document`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/investment/esign/onboarding': {
        title: `Esign Onboarding Document`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/payment': {
        title: `Payment`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/thankyou': {
        title: `Thank You`,
        metaDesc: `Welcome to the world of high return investment opportunities. Now diversify your portfolio beyond traditional assets and unlock great returns`,
        metaKeywords:
            'investment online, manage investments easily, low risk investments, safe investments, alternate investments, high returns, optimise risks, easy investments, investments in India, investment opportunities, alternative investments, high yield, alternate assets, compound your returns',
    },
    '/opportunity/[id]/payment/wallet': {
        title: `Thank You`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/terms-and-condition': {
        title: `Terms and Conditions | ${brandName}`,
        metaDesc: `Please read the Terms of Use carefully before using or registering on ${brandName}'s Website or accessing any material, information or services through the Website`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/terms-and-condition`,
    },
    '/disclaimer': {
        title: `Disclaimer | ${brandName}`,
        metaDesc: `${brandName}'s website is merely an intermediary or facilitator, and ${brandName} in no manner is engaged in either grant of loan or borrowing/fund raising from any participants on the website`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/disclaimer`,
    },
    '/risk-disclosure': {
        title: `Risk Disclosure | ${brandName}`,
        metaDesc: `All investments carry risk. Investors, prior to making an investment decision, should carefully evaluate whether the investment is suitable for them considering one’s knowledge/experience in investment planning, investment objectives, financial ability, and risks appetite.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/risk-disclosure`,
    },
    '/privacy-policy': {
        title: `Privacy Policy | ${brandName}`,
        metaDesc: `Please read the Privacy Policy carefully. Using ${brandName}'s Website indicates your agreement to all terms and conditions under this policy and consent to our collection, storage, use and disclosure of your Personal Information and Non-Personal Information as described in this Policy.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/privacy-policy`,
    },
    '/opportunity/[id]': {
        title: `${brandName} | Detailed overview of the opportunity`,
        metaDesc:
            'Detailed view of the opportunity to help you indetinfy the key characteristics of the asset class and parties involved',
    },
    '/nri-disclaimer': {
        title: `NRI Disclaimer | ${brandName}`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/nri-disclaimer`,
    },
    '/investor-ifa-disclaimer': {
        title: `IFA Disclaimer | ${brandName}`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/investor-ifa-disclaimer`,
    },
    '/survey/[formid]/[entityid]': {
        title: `${brandName} | We value your feedback`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords: "Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details",
    },
    '#signupModal': {
        title: `${brandName} - Sign up for the ${brandName} experience`,
    },
    '#loginModal': {
        title: `${brandName} - Log in for the ${brandName} experience`,
    },
    '#VerifyOtpModal': {
        title: `Verify Otp`,
    },
    '/ai-safe': {
        title: `${WalletName} | ${brandName}`,
        metaDesc: `View frequently asked questions (FAQs) by our investors about investing on ${brandName}. Get answers on fixed income debt investment and best returns on investment`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/ai-safe`,
    },
    '/blogs': {
        title: `Blogs | ${brandName}`,
        metaDesc: `Welcome to the world of high return investment opportunities. Now diversify your portfolio beyond traditional assets and unlock great returns`,
        metaKeywords:
            'investment blog, alternative investment blog, best investment blogs, finance blog, investment guide, altgraaf, personal finance, fixed income blog, finance articles, financial independence ',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/blogs`,
    },
    '/kyc-nudge': {
        title: `Complete KYC | ${brandName}`
    },
    '/referandearn': {
        title: `Refer & Earn`,
    },
    '/webinars': {
        title: `Webinars | ${brandName}`,
        metaDesc: `View some of the recent knowledge sessions and videos on altGraaf and its products. Learn the benefits of adding alternate fixed income products to your investment portfolio.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/webinars`,
    },
    '/media': {
        title: `In the News | ${brandName}`,
        metaDesc: `View latest media coverage on altGraaf and its products in various publications.`,
        metaKeywords:
            ' investment online, manage investments easily, low risk investments, safe investments, alternate investments, high returns, optimise risks, easy investments, investments in India, investment opportunities, alternative investments, high yield, alternate assets, compound your returns',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/media`,
    },
    '/opportunity/[id]/payment/freecharge': {
        title: `Payment | ${brandName}`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords: 'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
    },
    '/opportunity/[id]/payment/payu': {
        title: `Payment | ${brandName}`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords: 'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
    },
    '/dashboard/ai-safe': {
        title: `${WalletName}`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords: 'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/dashboard/my-referral': {
        title: `My Referral`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords: 'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/dashboard/income-certificate': {
        title: `Income Certificate`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure specturums`,
        metaKeywords: 'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/questionnaire': {
        title: `Questionnaire`,
    },
    '/welcome': {
        title: `Welcome To ${brandName}`,
    },
    '/welcome-back': {
        title: `Welcome To ${brandName}`,
    }
};
